import React, { useState, useEffect } from 'react'
import { FiTrash2, FiSearch, FiUsers, FiChevronUp, FiChevronDown, FiShield } from 'react-icons/fi'
import axios from 'axios'
import './UsersSection.css'
import { useAuth } from '../../../context/AuthContext'
import { toast } from 'react-toastify'

const UsersSection = () => {
	const { user } = useAuth()
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [sortConfig, setSortConfig] = useState({
		key: 'joinDate',
		direction: 'desc',
	})

	useEffect(() => {
		fetchUsers()
	}, [])

	const fetchUsers = async () => {
		try {
			setLoading(true)
			const response = await axios.get('https://findalike.me/api/admin/users', {
				withCredentials: true,
				headers: {
					'X-User-Id': user?._id,
				},
			})
			setUsers(response.data)
			setLoading(false)
		} catch (error) {
			console.error('Error fetching users:', error)
			setError('Failed to load users')
			setLoading(false)
		}
	}

	const handleDeleteUser = async userId => {
		if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
			return
		}

		try {
			await axios.delete(`https://findalike.me/api/admin/users/${userId}`, {
				withCredentials: true,
				headers: {
					'X-User-Id': user?._id,
				},
			})

			setUsers(prevUsers => prevUsers.filter(user => user._id !== userId))

			toast.success('User deleted successfully')
		} catch (error) {
			console.error('Error deleting user:', error)
			toast.error(error.response?.data?.message || 'Failed to delete user')
		}
	}

	const handleBlacklistUser = async (userId, userEmail) => {
		if (!window.confirm('Are you sure you want to delete and blacklist this user? This action cannot be undone.')) {
			return
		}

		try {
			await axios.delete(`https://findalike.me/api/admin/users/${userId}/delete-and-blacklist`, {
				withCredentials: true,
				headers: {
					'X-User-Id': user?._id,
				},
				data: {
					reason: 'Blacklisted by admin',
				},
			})

			setUsers(prevUsers => prevUsers.filter(user => user._id !== userId))
			toast.success('User deleted and blacklisted successfully')
		} catch (error) {
			console.error('Error blacklisting user:', error)
			toast.error(error.response?.data?.message || 'Failed to blacklist user')
		}
	}

	const formatDate = id => {
		try {
			const timestamp = parseInt(id.substring(0, 8), 16) * 1000
			return new Date(timestamp)
		} catch (error) {
			console.error('Error formatting date:', error)
			return new Date(0)
		}
	}

	const formatDateDisplay = id => {
		const date = formatDate(id)
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		})
	}

	const handleSort = key => {
		setSortConfig(prevConfig => ({
			key,
			direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
		}))
	}

	const sortedUsers = [...users].sort((a, b) => {
		if (sortConfig.key === 'joinDate') {
			const dateA = formatDate(a._id)
			const dateB = formatDate(b._id)
			return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA
		}
		if (sortConfig.key === 'friends') {
			const friendsA = a.FriendsAdded || 0
			const friendsB = b.FriendsAdded || 0
			return sortConfig.direction === 'asc' ? friendsA - friendsB : friendsB - friendsA
		}
		return 0
	})

	const filteredUsers = sortedUsers.filter(
		user =>
			user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			user.email.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const SortIcon = ({ columnKey }) => {
		if (sortConfig.key !== columnKey) {
			return <FiChevronDown className="sort-icon inactive" />
		}
		return sortConfig.direction === 'asc' ? (
			<FiChevronUp className="sort-icon active" />
		) : (
			<FiChevronDown className="sort-icon active" />
		)
	}

	if (loading) {
		return (
			<div className="loading-container">
				<span className="loading-text">Loading users...</span>
			</div>
		)
	}

	if (error) {
		return (
			<div className="users-section">
				<div className="error-message">
					{error}
					<button onClick={fetchUsers} className="retry-button">
						Try Again
					</button>
				</div>
			</div>
		)
	}

	return (
		<div className="users-section">
			<div className="users-header">
				<div className="users-title">
					<FiUsers className="users-icon" />
					<h2>Users Management</h2>
					<span className="users-count">{users.length} total</span>
				</div>
				<div className="search-bar">
					<FiSearch className="search-icon" />
					<input
						type="text"
						placeholder="Search users..."
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
					/>
				</div>
			</div>

			<div className="users-table-container">
				<table className="users-table">
					<thead>
						<tr>
							<th>User</th>
							<th className="sortable" onClick={() => handleSort('friends')}>
								Friends
								<SortIcon columnKey="friends" />
							</th>
							<th className="sortable" onClick={() => handleSort('joinDate')}>
								Join Date
								<SortIcon columnKey="joinDate" />
							</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{filteredUsers.map(user => (
							<tr key={user._id} className="user-row">
								<td>
									<div className="user-info">
										<img src={user.profilePicture || 'default-avatar-url'} alt={user.name} className="user-avatar" />
										<div className="user-details">
											<p className="user-name">{user.name}</p>
											<p className="user-email">{user.email}</p>
										</div>
									</div>
								</td>
								<td>
									<span className="friends-count">{user.FriendsAdded}</span>
								</td>
								<td>{formatDateDisplay(user._id)}</td>
								<td>
									<div className="action-buttons">
										<button onClick={() => handleDeleteUser(user._id)} className="delete-button" title="Delete user">
											<FiTrash2 />
										</button>
										<button
											onClick={() => handleBlacklistUser(user._id, user.email)}
											className="blacklist-button"
											title="Delete and blacklist user">
											<FiShield />
										</button>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default UsersSection
