// src/App.js
import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from './context/AuthContext'
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import './i18n'
import AdminPanel from './Pages/Admin/AdminPanel'
import ContactPage from './Pages/Contact/ContactPage'

// Lazy load components for better performance
const Login = React.lazy(() => import('./Pages/Auth/Login/Login'))
const Register = React.lazy(() => import('./Pages/Auth/Register/Register'))
const EditProfile = React.lazy(() => import('./Pages/Chat/EditProfile/EditProfile'))
const Chat = React.lazy(() => import('./Pages/Chat/ChatLayout'))
const Home = React.lazy(() => import('./Pages/Home/Home'))
const ForgotPassword = React.lazy(() => import('./Pages/Auth/PasswordChange/ForgotPassword/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./Pages/Auth/PasswordChange/ResetPassword/ResetPassword'))

const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy/PrivacyPolicy'))
const LandingPageExpert = React.lazy(() => import('./Pages/LandingPageExpert/LandingPageExpert'))
const ExpertDashboard = React.lazy(() => import('./Pages/ExpertDashboard/ExpertDashboard'))
const ExpertLogin = React.lazy(() => import('./Pages/ExpertDashboard/ExpertLogin'))
const ExpertRegister = React.lazy(() => import('./Pages/ExpertDashboard/ExpertRegister'))

// Loading component for suspense fallback
const LoadingSpinner = () => (
	<div role="status" aria-label="Loading" className="spinner-container">
		<div className="spinner"></div>
	</div>
)

const App = () => {
	const { user } = useAuth()
	const location = useLocation()
	const isHomePage = location.pathname === '/'

	useEffect(() => {
		// Handle viewport height for mobile browsers
		const setVh = () => {
			const vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
		}

		setVh()
		window.addEventListener('resize', setVh)

		return () => window.removeEventListener('resize', setVh)
	}, [])

	const ProtectedRoute = ({ children }) => {
		if (!user) return <Navigate to="/register" replace />
		return children
	}

	const ProtectedAdminRoute = ({ children }) => {
		const { user } = useAuth()
		const navigate = useNavigate()

		useEffect(() => {
			if (!user) {
				navigate('/login')
				return
			}

			if (user.isAdmin !== true) {
				console.log('Access denied - not admin:', user)
				navigate('/chat')
				toast.error('Brak dostępu do panelu administratora')
				return
			}
		}, [user, navigate])

		return children
	}

	const ProtectedExpertRoute = ({ children }) => {
		const { user } = useAuth()

		if (!user || !user.isExpert) {
			return <Navigate to="/expert/login" replace />
		}

		return children
	}

	return (
		<HelmetProvider>
			<div className={isHomePage ? 'div-home' : 'div-main'}>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					zIndex={9999}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
				<React.Suspense fallback={<LoadingSpinner />}>
					<Routes>
						{/* Public routes */}
						<Route path="/login" element={!user ? <Login /> : <Navigate to="/chat" replace />} />
						<Route path="/register" element={!user ? <Register /> : <Navigate to="/chat" replace />} />

						{/* Protected routes */}
						<Route
							path="/edit-profile"
							element={
								<ProtectedRoute>
									<EditProfile />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/chat"
							element={<ProtectedRoute>{user?.isAdmin ? <Navigate to="/admin" replace /> : <Chat />}</ProtectedRoute>}
						/>

						{/* Password reset routes */}
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password/:token" element={<ResetPassword />} />

						{/* Home route */}
						<Route path="/" element={<Home />} />

						{/* Admin route */}
						<Route
							path="/admin"
							element={
								<ProtectedRoute>
									<ProtectedAdminRoute>
										<AdminPanel />
									</ProtectedAdminRoute>
								</ProtectedRoute>
							}
						/>

						{/* Contact route */}
						<Route path="/contact" element={<ContactPage />} />

						{/* Privacy Policy route */}
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />

						{/* Expert Info route */}
						<Route path="/expert-info" element={<LandingPageExpert />} />

						{/* Expert routes */}
						<Route path="/expert" element={<LandingPageExpert />} />
						<Route
							path="/expert/dashboard"
							element={
								<ProtectedExpertRoute>
									<ExpertDashboard />
								</ProtectedExpertRoute>
							}
						/>
						<Route
							path="/expert/login"
							element={user?.isExpert ? <Navigate to="/expert/dashboard" replace /> : <ExpertLogin />}
						/>
						<Route
							path="/expert/register"
							element={user?.isExpert ? <Navigate to="/expert/dashboard" replace /> : <ExpertRegister />}
						/>

						{/* Catch all route */}
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</React.Suspense>
			</div>
		</HelmetProvider>
	)
}

export default App
